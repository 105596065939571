<template>
  <div id="collection">
    <el-tabs type="border-card">
      <el-tab-pane>
        <span slot="label"
          ><i class="el-icon-s-promotion"></i> 自己发起的回款</span>
        <setOrder />
      </el-tab-pane>
      <el-tab-pane label="已用插件代替"
        ><span slot="label"><i class="el-icon-s-order"></i> 待审批回款</span>
        <approvaling />
      </el-tab-pane>
      <el-tab-pane
        ><span slot="label"
          ><i class="el-icon-folder-checked"></i> 已审批回款</span
        >
        <already />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
  
  <script>
import setOrder from "@/views/audit/modules/collectionCom/setOrder.vue";
import approvaling from "@/views/audit/modules/collectionCom/approvaling.vue";
import already from "@/views/audit/modules/collectionCom/already.vue";

export default {
  components: { setOrder, approvaling,already },
  data() {
    return {};
  },
};
</script>
  
<style lang="less" scoped>
#collection{
  height: 100%;
  overflow-y: scroll;
}
/deep/ .el-tabs__nav-scroll {
  .is-active {
    color: #3278FE !important;
  }
}
</style>