<template>
  <el-card id="niche">
    <header>
      <h2 style="margin-bottom: 20px">
        筛选浏览<i class="el-icon-s-help"></i>
      </h2>
      <div class="top-box">
        <div class="select-item">
          <span>回款方式</span>
          <el-select
          size="mini"
            v-model="screenForm.moneyWay"
            filterable
            clearable
            placeholder="选择回款方式"
          >
            <el-option
              v-for="item in staffList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-item">
          <span>审核状态</span>
          <el-select
          size="mini"
            v-model="screenForm.auditStatus"
            filterable
            clearable
            placeholder="选择审核状态"
          >
            <el-option
              v-for="item in KHoptions"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-item">
          <span>公司名称</span>
          <el-input
            v-model="screenForm.clientName"
            placeholder="输入公司名称"
            size="mini"
            clearable
          ></el-input>
        </div>
        <div class="select-item" v-if="roleName == '企业'">
          <span>创建用户</span>
          <el-input
          size="mini"
            v-model="screenForm.createUserName"
            placeholder="输入完整用户名"
            clearable
          ></el-input>
        </div>
      </div>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <!-- <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="addNiche"
              size="mini"
              class="searchBtn"
              >新增回款</el-button
            > -->
          </div>
        </div>
        <div class="right">
          <el-button
            type="success"
            icon="el-icon-search"
            @click="query"
            size="mini"
            >查询
          </el-button>
          <el-button
            type="success"
            icon="el-icon-refresh"
            @click="reset"
            size="mini"
            >重置
          </el-button>
        </div>
      </div>
    </header>
    <hr>
    <main>
      <div class="table-box">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="已回款列表" name="first"
            ><el-table
              :data="tableData"
              v-loading="tableLoad"
              element-loading-text="加载数据中..."
              border
              class="table"
              ref="multipleTable"
              @row-dblclick="dblclick"
              header-cell-class-name="table-header"
            >
              <!-- 多选按钮 -->
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                type="index"
                align="center"
                label="序号"
                width="50"
              >
              </el-table-column>
              <!-- <el-table-column label="审批工单类型" align="center">
          <template #default="scope">
            <div>{{ setShowTypeName(scope.row.bsf) }}</div>
          </template>
        </el-table-column> -->
              <el-table-column label="回款期数" align="center">
                <template #default="scope">
                  <div v-if="scope.row.periods">
                    第
                    <span style="font-weight: bold; font-size: 18px">{{
                      scope.row.periods
                    }}</span>
                    次
                  </div>
                  <div v-else>未回款</div>
                </template>
              </el-table-column>
              <el-table-column label="审核状态" align="center">
                <template #default="scope">
                  <el-tag
                    size="mini"
                    :type="setType(scope.row.audit_status)"
                    >{{ scope.row.audit_status | setStatus }}</el-tag
                  >
                </template>
              </el-table-column>
              <el-table-column label="已回金额/合同金额" align="center">
                <template #default="scope">
                  <div>
                    {{ scope.row.money }}/
                    <span style="font-weight: bold; font-size: 18px">{{
                      scope.row.ht_money
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(v, i) in dataLabel1"
                :key="i"
                :label="v.label"
                :prop="v.name"
                align="center"
              ></el-table-column>
              <el-table-column
                fixed="right"
                label="操作"
                width="200"
                align="center"
                v-if="roleName == '企业'"
              >
                <template #default="scope">
                  <!-- <el-button
                type="success"
                @click="editNiche(scope.row)"
                size="mini"
                >编辑</el-button
              > -->
                  <el-button
                    type="danger"
                    @click="delNiche(scope.row)"
                    size="mini"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table></el-tab-pane
          >
          <el-tab-pane label="未回款列表" name="second"
            ><el-table
              :data="tableData"
              v-loading="tableLoad"
              element-loading-text="加载数据中..."
              border
              class="table"
              ref="multipleTable"
              @row-dblclick="dblclick"
              header-cell-class-name="table-header"
            >
              <!-- 多选按钮 -->
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                type="index"
                align="center"
                label="序号"
                width="50"
              >
              </el-table-column>
              <!-- <el-table-column label="审批工单类型" align="center">
          <template #default="scope">
            <div>{{ setShowTypeName(scope.row.bsf) }}</div>
          </template>
        </el-table-column> -->
              <el-table-column label="回款期数" align="center">
                <template #default="scope">
                  <div v-if="scope.row.periods">
                    第
                    <span style="font-weight: bold; font-size: 18px">{{
                      scope.row.periods
                    }}</span>
                    次
                  </div>
                  <div v-else>未回款</div>
                </template>
              </el-table-column>
              <el-table-column label="审核状态" align="center">
                <template #default="scope">
                  <el-tag
                    size="mini"
                    :type="setType(scope.row.audit_status)"
                    >{{ scope.row.audit_status | setStatus }}</el-tag
                  >
                </template>
              </el-table-column>
              <el-table-column label="待回金额/合同金额" align="center">
                <template #default="scope">
                  <div>
                    {{ scope.row.dai_money }}/
                    <span style="font-weight: bold; font-size: 18px">{{
                      scope.row.ht_money
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(v, i) in dataLabel2"
                :key="i"
                :label="v.label"
                :prop="v.name"
                align="center"
              ></el-table-column>
              <el-table-column
                fixed="right"
                label="操作"
                width="200"
                align="center"
                v-if="roleName == '企业'"
              >
                <template #default="scope">
                  <!-- <el-button
                type="success"
                @click="editNiche(scope.row)"
                size="mini"
                >编辑</el-button
              > -->
                  <el-button
                    type="danger"
                    @click="delNiche(scope.row)"
                    size="mini"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table></el-tab-pane
          >
        </el-tabs>
      </div>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>

    <!-- 弹框 -->
    <el-dialog
      :title="setText + '回款'"
      :visible.sync="outerVisible"
      @close="closeDialog"
    >
      <el-descriptions title="回款详情信息" :column="3" border v-if="form">
        <el-descriptions-item label="审核状态"
          ><el-tag size="mini" :type="setType(form.audit_status)">{{
            form.audit_status | setStatus
          }}</el-tag></el-descriptions-item
        >
        <el-descriptions-item label="合同编号">
          <el-tag size="mini" type="warning">{{
            form.contract_number
          }}</el-tag></el-descriptions-item
        >
        <el-descriptions-item label="回款编号">
          <el-tag size="mini" type="warning">{{
            form.money_number
          }}</el-tag></el-descriptions-item
        >
        <el-descriptions-item :label="v.label" v-for="v in detailsLabel">{{
          form[v.name]
        }}</el-descriptions-item>
        <el-descriptions-item label="回款备注">{{
          form.remark
        }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();

import { select, add, deleteNiche, update } from "@/api/collection";
export default {
  data() {
    return {
      roleName: null,
      collectionStatus: "1",
      activeName: "first",
      staffList: [
        { id: 1, label: "现金", value: "现金" },
        { id: 2, label: "微信支付", value: "微信支付" },
        { id: 3, label: "支付宝", value: "支付宝" },
        { id: 4, label: "转账回款", value: "转账回款" },
        { id: 5, label: "支票", value: "支票" },
        { id: 6, label: "其它", value: "其它" },
      ],
      tableLoad: false, // 表格加载效果
      screenForm: {}, // 筛选表单
      KHoptions: [
        { id: 1, label: "审核中", value: 1 },
        { id: 2, label: "审核成功", value: 2 },
        { id: 3, label: "审核失败", value: 3 },
      ],
      JDoptions: [
        "初步沟通(10%)",
        "方案报价(50%)",
        "谈判协商(80%)",
        "赢单(100%)",
        "输单(0%)",
        "无效",
      ],
      setText: "",
      outerVisible: false,
      form: {},
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //总条数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //每页显示的数量
      page: 1, //第几页
      size: 10, //每页显示的数量
      detailsLabel: [
        {
          label: "公司名称",
          name: "client_name",
        },
        {
          label: "回款金额",
          name: "money",
        },
        {
          label: "回款方式",
          name: "money_way",
        },
        {
          label: "创建用户姓名",
          name: "create_user_name",
        },
        {
          label: "回款日期",
          name: "create_date_money",
        },
        {
          label: "创建时间",
          name: "create_date",
        },
      ],
      dataLabel1: [
        // {
        //   label: "已回款金额",
        //   name: "money",
        // },
        {
          label: "回款方式",
          name: "money_way",
        },
        {
          label: "公司名称",
          name: "client_name",
        },
        {
          label: "回款编号",
          name: "money_number",
        },
        {
          label: "合同编号",
          name: "contract_number",
        },
        {
          label: "创建用户姓名",
          name: "create_user_name",
        },
        {
          label: "回款日期",
          name: "create_date_money",
        },
        {
          label: "创建时间",
          name: "create_date",
        },
        {
          label: "回款备注",
          name: "remark",
        },
      ],
      dataLabel2: [
        // {
        //   label: "待回款金额",
        //   name: "dai_money",
        // },
        {
          label: "回款方式",
          name: "money_way",
        },
        {
          label: "公司名称",
          name: "client_name",
        },
        {
          label: "回款编号",
          name: "money_number",
        },
        {
          label: "合同编号",
          name: "contract_number",
        },
        {
          label: "创建用户姓名",
          name: "create_user_name",
        },
        {
          label: "回款日期",
          name: "create_date_money",
        },
        {
          label: "创建时间",
          name: "create_date",
        },
        {
          label: "回款备注",
          name: "remark",
        },
      ],
    };
  },
  filters: {
    setStatus(data) {
      if (data == 1) {
        return "审核中";
      } else if (data == 2) {
        return "审核成功";
      } else if (data == 3) {
        return "审核失败";
      }
    },
  },
  async created() {
    await this.getList();
    this.roleName = localStorage.getItem("roleName");
  },
  methods: {
    handleClick(tab, event) {
      if (tab.label == "已回款列表") {
        this.collectionStatus = "1";
      } else {
        this.collectionStatus = "2";
      }

      this.getList();

      console.log(tab.label, event);
    },
    // 审核状态tag的type
    setType(data) {
      if (data == 1) {
        return "info";
      } else if (data == 2) {
        return "success";
      } else if (data == 3) {
        return "danger";
      }
    },
    // 查询
    query() {
      this.getList();
    },
    // 重置
    reset() {
      this.page = 1;
      this.size = 10;
      this.screenForm = {};
      this.getList();
    },
    // 单个删除
    delNiche(row) {
      this.$confirm(
        `是否要删除 "${row.client_name}" 的该回款信息?`,
        "删除回款",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          let params = {
            id: row.id,
          };
          let res = await deleteNiche(params);
          if (res.data.statusCode == "00000") {
            await this.closeDialog();
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
    // 手动验证方法
    verification1() {
      if (!this.form.clentName) {
        Message.warning("请选择客户");
        return false;
      } else {
        return true;
      }
    },
    // 新增或修改的提交
    async onSubmit() {
      let bool = await this.verification1();
      if (!bool) {
        return;
      }
      const loading = await this.$loading({
        lock: true,
        text: this.setText + "中,请稍等...",
      });
      let params;
      let res;
      if (this.setText == "新增") {
        params = {
          clentId: this.form.clentName[0],
          clentName: this.form.clentName[1],
          money: this.form.money,
          name: this.form.name,
          predictMakeTime: this.form.predictMakeTime,
          remarke: this.form.remarke,
          shangjiStage: this.form.shangjiStage,
        };
        res = await add(params);
      } else if (this.setText == "编辑") {
        params = {
          ...this.form,
        };
        res = await update(params);
      }

      if (res.data.statusCode == "00000") {
        await this.closeDialog();
        Message.success(res.data.message);
        loading.close();
      } else {
        Message.error(res.data.message);
        loading.close();
      }
    },
    // 双击单行查看详情
    dblclick(row) {
      this.form = row;
      this.setText = "查看";
      this.outerVisible = true;
    },

    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 编辑回款
    editNiche(row) {
      console.log("row", row);
      this.form = row;
      this.setText = "编辑";
      this.outerVisible = true;
    },
    // 新增回款
    addNiche() {
      this.setText = "新增";
      this.outerVisible = true;
    },
    // 关闭弹框
    closeDialog() {
      this.form = {};
      this.outerVisible = false;
      this.getList();
    },
    // 获取表格数据
    async getList() {
      this.tableLoad = true;
      let id = localStorage.getItem("id");
      let params = {
        page: this.page,
        size: this.size,
        yes_no: this.collectionStatus,
        ...this.screenForm,
      };
      if (this.roleName == "企业") {
        params.companyId = id;
      } else {
        params.companyId = localStorage.getItem("parentId");
        params.createUserId = id;
      }
      let res = await select(params);
      this.pageTotal = res.data.total;
      this.tableData = res.data.data;
      this.tableLoad = false;
      // statusCode  合计金额
      console.log("返回参数", res.data);
      Message.success("已刷新数据");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278FE;
  border-color: #3278FE;
  &:hover {
    opacity: 0.7;
  }
}

#niche {
  .top-box {
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
    // line-height: 30px;
    margin-bottom: 40px;
    /deep/ .el-select {
      width: 100%;
    }
    .select-item {
      width: 20%;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        min-width: 60px;
        text-align: right;
        align-items: center;
        font-size: 14px;
      }
    }
  }
  .search {
    display: flex;
    .left {
      width: 50%;
      display: flex;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;
      line-height: 30px;
      .left-wrap {
        display: flex;
        align-items: center;
      }
    }
    .right {
      width: 50%;
      text-align: right;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-end;
      align-items: center;
      height: 100%;
    }
  }
  /deep/ .el-table {
    // max-height: 555px;
    // padding-left: 50px;
    // box-sizing: border-box;
    // overflow-y: scroll;
  }
  /deep/.el-dialog__wrapper {
    .el-dialog {
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      .el-select,
      .el-input {
        width: 100%;
      }
      .el-textarea__inner {
        min-height: 100px !important;
      }
      .el-dialog__header {
        text-align: center;
        .el-dialog__title {
          font-weight: bold;
          font-size: 20px;
        }
      }
      .el-dialog__body {
        position: relative;
        // min-height: 500px;
        .el-form {
        }
        .el-form-item__content {
          // 修改样式会混乱
          .btn-box {
            text-align: right;
          }
        }
      }
    }
  }
}
main {
  .table-box {
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 480px;
    overflow-y: scroll;
    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style: none;
    /*火狐下隐藏滚动条*/
    scrollbar-width: none;
  }
  .table-box::-webkit-scrollbar {
    display: none;
  }
  .pagination {
    text-align: right;
    margin: 20px 0;
  }
}
</style>