/**
 * call
 * 呼叫
 */

import request from '@/utils/request'

// 查询回款
export const select = (data) => {
    return request({
        method: 'post',
        // url: '/returnedMoney/selectUserId',
        url: '/returnedMoney/list',
		
        data
    })
}

// 待审批回款
export const remindReturnedMoney = (data) => {
	return request({
		url: "/returnedMoney/remindReturnedMoney",
		method: "post",
		data
	});
};

// 查询回款审批人信息
export const selectReturnedMoney = (data) => {
	return request({
		url: "/returnedMoney/selectReturnedMoney",
		method: "post",
		data
	});
};

// 修改回款
export const updateCollection = (data) => {
	return request({
		url: "/returnedMoney/update",
		method: "post",
		data
	});
};

// 修改回款审批人信息
export const updateReturnedMoney = (data) => {
	return request({
		url: "/returnedMoney/updateReturnedMoney",
		method: "post",
		data
	});
};

// 已审批回款（我审批过的回款）
export const returnedMoney_participantContract = (data) => {
	return request({
		url: "/returnedMoney/participantContract",
		method: "post",
		data
	});
};




// 新建回款
export const add = (data) => {
    // return request({
    //     method: 'post',
    //     url: '/business/add',
    //     data
    // })
}

// 删除回款
export const deleteNiche = (data) => {
    return request({
        method: 'post',
        url: '/returnedMoney/delete',
        data
    })
}

// 编辑回款()
export const update = (data) => {
    // return request({
    //     method: 'post',
    //     url: '/business/update',
    //     data
    // })
}