<template>
  <el-card id="niche">
    <header>
      <h2 style="margin-bottom: 20px">
        审批回款工单<i class="el-icon-bangzhu"></i>
      </h2>

      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <!-- <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="addNiche"
              size="mini"
              class="searchBtn"
              >新增回款</el-button
            > -->
          </div>
        </div>
        <div class="right">
          <!-- <el-button
            type="success"
            icon="el-icon-search"
            @click="query"
            size="mini"
            >查询
          </el-button> -->
          <el-button
            type="success"
            icon="el-icon-refresh"
            @click="reset"
            size="mini"
            >刷新
          </el-button>
        </div>
      </div>
    </header>
    <hr>
    <main>
      <div class="table-box">
        <el-table
          :data="tableData"
          v-loading="tableLoad"
          element-loading-text="加载数据中..."
          border
          class="table"
          ref="multipleTable"
          @row-dblclick="dblclick"
          header-cell-class-name="table-header"
        >
          <!-- 多选按钮 -->
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column type="index" align="center" label="序号" width="50">
          </el-table-column>
          <!-- <el-table-column label="审批工单类型" align="center">
          <template #default="scope">
            <div>{{ setShowTypeName(scope.row.bsf) }}</div>
          </template>
        </el-table-column> -->
          <el-table-column label="回款期数" align="center">
            <template #default="scope">
              <div v-if="scope.row.periods">
                第
                <span style="font-weight: bold; font-size: 18px">{{
                  scope.row.periods
                }}</span>
                次
              </div>
              <div v-else>未回款</div>
            </template>
          </el-table-column>
          <el-table-column label="审核状态" align="center">
            <template #default="scope">
              <el-tag size="mini" :type="setType(scope.row.audit_status)">{{
                scope.row.audit_status | setStatus
              }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column
            label="合同金额"
            prop="fee_total"
            v-if="parentId == 7"
            align="center"
          ></el-table-column>
          <el-table-column
            label="合同金额"
            v-else
            prop="contract_money"
            align="center"
          ></el-table-column>
          <el-table-column
            v-for="(v, i) in dataLabel"
            :key="i"
            :label="v.label"
            :prop="v.name"
            align="center"
          ></el-table-column>
          <el-table-column
            fixed="right"
            width="300"
            label="操作"
            align="center"
          >
            <template #default="scope">
              <el-button
                type="success"
                @click="editNiche(scope.row)"
                size="mini"
                >编辑</el-button
              >
              <el-button
                v-if="roleName == '企业'"
                type="danger"
                @click="delNiche(scope.row)"
                size="mini"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>

    <!-- 弹框 -->
    <el-dialog
      :title="setText + '回款'"
      :visible.sync="outerVisible"
      @close="closeDialog"
    >
      <!-- 查看 -->

      <div
        v-if="setText == '查看'"
        style="padding-bottom: 20px; box-sizing: border-box"
      >
        <el-descriptions title="回款详情信息" :column="3" border v-if="form">
          <el-descriptions-item label="审核状态"
            ><el-tag size="mini" :type="setType(form.audit_status)">{{
              form.audit_status | setStatus
            }}</el-tag></el-descriptions-item
          >
          <el-descriptions-item label="合同编号">
            <el-tag size="mini" type="warning">{{
              form.contract_number
            }}</el-tag></el-descriptions-item
          >
          <el-descriptions-item label="回款编号">
            <el-tag size="mini" type="warning">{{
              form.money_number
            }}</el-tag></el-descriptions-item
          >
          <el-descriptions-item label="合同金额" v-if="parentId == 7">{{
            form.fee_total
          }}</el-descriptions-item>
          <el-descriptions-item label="合同金额" v-else>{{
            form.contract_money
          }}</el-descriptions-item>
          <el-descriptions-item :label="v.label" v-for="v in detailsLabel">{{
            form[v.name]
          }}</el-descriptions-item>
          <el-descriptions-item label="回款备注">{{
            form.remark
          }}</el-descriptions-item>
        </el-descriptions>
        <h3 style="margin: 20px 0">审批人</h3>
        <el-steps
          style="margin-left: 50px"
          :active="currentSpman.index"
          align-center
          direction="vertical"
        >
          <el-step style="min-height: 65px" v-for="v in spmanArr" :key="v.id">
            <div slot="title">审批人 — {{ v.orderTypeName }}</div>
            <div slot="description" style="font-weight: bold">
              {{ getStatus(v) }}
              <div style="color: #333">备注： {{ v.auditRemark || "无" }}</div>
            </div>
            <!-- <div slot="icon">footer</div> -->
          </el-step>
        </el-steps>
      </div>

      <!-- 编辑 -->
      <div v-if="setText == '编辑'">
        <el-form
          ref="form"
          label-position="right"
          size="medium"
          :model="form"
          label-width="100px"
        >
          <!-- 表单过多时使用下面这种布局 -->
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="公司名称">
                <el-input
                  v-model="form.client_name"
                  clearable
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="合同编号">
                <el-input
                  v-model="form.contract_number"
                  clearable
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="本次回款金额">
                <el-input
                  v-model="form.money"
                  clearable
                  type="number"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="回款方式">
                <el-select
                  v-model="form.moneyWay"
                  clearable
                  placeholder="选择回款方式"
                >
                  <el-option
                    v-for="item in staffList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="本次回款时间">
            <el-date-picker
              v-model="form.create_date_money"
              type="datetime"
              clearable
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="回款备注">
            <el-input
              type="textarea"
              v-model="form.remark"
              maxlength="1000"
              show-word-limit
              placeholder="商机相关备注"
              autosize
            ></el-input>
          </el-form-item>

          <el-form-item label="审核回款">
            <el-steps :active="1" finish-status="success" simple>
              <el-step
                :title="getStatus(v)"
                v-for="v in spmanArr"
                :key="v.id"
              ></el-step>
            </el-steps>
          </el-form-item>

          <el-form-item>
            <div class="btn-box">
              <el-button @click="closeDialog">取消</el-button>
              <el-button
                type="primary"
                @click="examine"
                v-if="currentSpman.status == 2 && form.fee_total"
                v-show="oldMoney * 1 >= form.fee_total * 1"
                >审核回款</el-button
              >
              <el-button
                type="primary"
                @click="examine"
                v-if="currentSpman.status == 2 && form.contract_money"
                v-show="oldMoney * 1 >= form.contract_money * 1"
                >审核回款</el-button
              >
              <el-button
                type="primary"
                @click="onSubmit"
                v-if="setText != '查看'"
                >添加回款记录</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 审批 -->
    <el-dialog
      title="审核回款"
      :visible.sync="examineVisible"
      @close="closeExamine"
    >
      <el-input
        type="textarea"
        v-model="examineRemark"
        maxlength="1000"
        show-word-limit
        placeholder="审核时的备注"
        autosize
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="examineVisible = false">取 消</el-button>
        <el-button type="danger" @click="examineFN('驳回')">驳 回</el-button>
        <el-button type="primary" @click="examineFN('通过')">通 过</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();

import {
  remindReturnedMoney,
  add,
  deleteNiche,
  updateCollection,
  updateReturnedMoney,
  selectReturnedMoney,
} from "@/api/collection";
export default {
  data() {
    return {
      parentId: null,
      roleName: null,
      oldMoney: 0,
      examineRemark: null,
      examineVisible: false,
      staffList: [
        { id: 1, label: "现金", value: "现金" },
        { id: 2, label: "微信支付", value: "微信支付" },
        { id: 3, label: "支付宝", value: "支付宝" },
        { id: 4, label: "转账回款", value: "转账回款" },
        { id: 5, label: "支票", value: "支票" },
        { id: 6, label: "其它", value: "其它" },
      ],
      tableLoad: false, // 表格加载效果
      screenForm: {}, // 筛选表单
      KHoptions: [
        { id: 1, label: "审核中", value: 1 },
        { id: 2, label: "审核成功", value: 2 },
        { id: 3, label: "审核失败", value: 3 },
      ],
      JDoptions: [
        "初步沟通(10%)",
        "方案报价(50%)",
        "谈判协商(80%)",
        "赢单(100%)",
        "输单(0%)",
        "无效",
      ],
      setText: "",
      outerVisible: false,
      form: {},
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //总条数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //每页显示的数量
      page: 1, //第几页
      size: 10, //每页显示的数量
      spmanArr: [],
      currentSpman: {},
      detailsLabel: [
        {
          label: "公司名称",
          name: "client_name",
        },

        {
          label: "回款金额",
          name: "money",
        },
        {
          label: "回款方式",
          name: "money_way",
        },
        {
          label: "创建用户姓名",
          name: "create_user_name",
        },
        {
          label: "回款日期",
          name: "create_date_money",
        },
        {
          label: "创建时间",
          name: "create_date",
        },
      ],
      // detailsLabel: [
      //   {
      //     label: "公司名称",
      //     name: "client_name",
      //   },

      //   {
      //     label: "回款金额",
      //     name: "money",
      //   },
      //   {
      //     label: "回款方式",
      //     name: "money_way",
      //   },
      //   {
      //     label: "创建用户姓名",
      //     name: "create_user_name",
      //   },
      //   {
      //     label: "回款日期",
      //     name: "create_date_money",
      //   },
      //   {
      //     label: "创建时间",
      //     name: "create_date",
      //   },
      // ],
      // dataLabel: [
      //   {
      //     label: "回款金额",
      //     name: "money",
      //   },
      //   {
      //     label: "回款方式",
      //     name: "money_way",
      //   },
      //   {
      //     label: "公司名称",
      //     name: "client_name",
      //   },
      //   {
      //     label: "回款编号",
      //     name: "moneyNumber",
      //   },
      //   {
      //     label: "合同编号",
      //     name: "contractNumber",
      //   },
      //   {
      //     label: "创建用户姓名",
      //     name: "create_user_name",
      //   },
      //   {
      //     label: "回款日期",
      //     name: "create_date_money",
      //   },
      //   {
      //     label: "创建时间",
      //     name: "create_date",
      //   },
      //   {
      //     label: "回款备注",
      //     name: "remark",
      //   },
      // ],
      dataLabel: [
        {
          label: "回款金额",
          name: "money",
        },
        {
          label: "回款方式",
          name: "money_way",
        },
        {
          label: "公司名称",
          name: "client_name",
        },
        {
          label: "回款编号",
          name: "money_number",
        },
        {
          label: "合同编号",
          name: "contract_number",
        },
        {
          label: "创建用户姓名",
          name: "create_user_name",
        },
        {
          label: "回款日期",
          name: "create_date_money",
        },
        {
          label: "创建时间",
          name: "create_date",
        },
        {
          label: "回款备注",
          name: "remark",
        },
      ],
    };
  },
  filters: {
    setStatus(data) {
      if (data == 1) {
        return "审核中";
      } else if (data == 2) {
        return "审核成功";
      } else if (data == 3) {
        return "审核失败";
      }
    },
  },
  created() {
    this.$nextTick(async () => {
      await this.getList();
      this.roleName = localStorage.getItem("roleName");
      this.parentId = localStorage.getItem("parentId");

      console.log("roleName", this.roleName);
    });
  },
  methods: {
    // 审核确认
    async examineFN(str) {
      let params = {
        moneyNumber: this.form.money_number,
        tableId: this.currentSpman.id,
        auditRemark: this.examineRemark,
        returnedMoneyId: this.form.id,
      };

      if (str == "通过") {
        params.status = "3";
      } else {
        params.status = "4";
      }

      let res = await updateReturnedMoney(params);
      if (res.data.statusCode == "00000") {
        // await this.getOrder();
        Message.success("审核通过");
        this.examineVisible = false;
        await this.closeDialog();
      } else {
        Message.error(res.data.message);
      }
    },
    // 关闭审核弹框
    closeExamine() {
      this.examineRemark = null;
      this.examineVisible = false;
    },
    // 审批通过或驳回
    examine() {
      console.log("this.currentSpman", this.currentSpman);
      this.examineVisible = true;
    },
    // 审核状态tag的type
    setType(data) {
      if (data == 1) {
        return "info";
      } else if (data == 2) {
        return "success";
      } else if (data == 3) {
        return "danger";
      }
    },
    // 查询
    query() {
      this.getList();
    },
    // 重置
    reset() {
      this.page = 1;
      this.size = 10;
      this.screenForm = {};
      this.getList();
    },
    // 单个删除
    delNiche(row) {
      this.$confirm(
        `是否要删除客户 "${row.client_name}" 的该回款信息?`,
        "删除回款",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          let params = {
            id: row.id,
          };
          let res = await deleteNiche(params);
          if (res.data.statusCode == "00000") {
            await this.closeDialog();
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
    // 手动验证方法
    verification1() {
      if (!this.form.clentName) {
        Message.warning("请选择客户");
        return false;
      } else {
        return true;
      }
    },
    // 新增或修改的提交
    async onSubmit() {
      // let bool = await this.verification1();
      // if (!bool) {
      //   return;
      // }
      const loading = await this.$loading({
        lock: true,
        text: this.setText + "中,请稍等...",
      });
      let params;
      let res;
      if (this.setText == "新增") {
        // params = {
        //   clentId: this.form.clentName[0],
        //   clentName: this.form.clentName[1],
        //   money: this.form.money,
        //   name: this.form.name,
        //   predictMakeTime: this.form.predictMakeTime,
        //   remarke: this.form.remarke,
        //   shangjiStage: this.form.shangjiStage,
        // };
        // res = await add(params);
      } else if (this.setText == "编辑") {
        this.form.periods = this.form.periods + 1;
        this.form.money = this.oldMoney * 1 + this.form.money * 1;
        params = {
          auditStatus: this.form.audit_status,
          clientId: this.form.client_id,
          clientName: this.form.client_name,
          companyId: this.form.company_id,
          contractId: this.form.contract_id,
          contractNumber: this.form.contract_number,
          createDate: this.form.create_date,
          createDateMoney: this.form.create_date_money,
          createUserId: this.form.create_user_id,
          createUserName: this.form.create_user_name,
          feeTotal: this.form.fee_total,
          moneyWay: this.form.money_way,
          moneyumber: this.form.money_number,
          ...this.form,
        };

        res = await updateCollection(params);
      }

      if (res.data.statusCode == "00000") {
        await this.closeDialog();
        Message.success(res.data.message);
        loading.close();
      } else {
        Message.error(res.data.message);
        loading.close();
      }
    },
    // 双击单行查看详情
    async dblclick(row) {
      this.form = row;
      this.setText = "查看";
      await this.getOrder();
      this.outerVisible = true;
    },

    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 编辑回款
    async editNiche(row) {
      console.log("row", row);
      this.oldMoney = row.money;
      this.form = row;
      this.form.money = 0;
      this.setText = "编辑";
      await this.getOrder();
      this.outerVisible = true;
    },
    // 新增回款
    addNiche() {
      this.setText = "新增";
      this.outerVisible = true;
    },
    // 关闭弹框
    closeDialog() {
      this.form = {};
      this.oldMoney = 0;
      this.outerVisible = false;
      this.getList();
    },
    // 审批描述
    getStatus(obj) {
      let str;
      if (obj.status == 1) {
        str = "(待审批)";
      } else if (obj.status == 2) {
        str = "(审核中)";
      } else if (obj.status == 3) {
        str = "(审批通过)";
      } else if (obj.status == 4) {
        str = "(审批未通过)";
      }
      return `${obj.orderTypeName}${str}`;
    },
    // 获取回款审批人信息
    async getOrder() {
      let params = {
        moneyNumber: this.form.money_number,
      };
      let Approval = await selectReturnedMoney(params);
      if (Approval.data.data.length != 0) {
        this.spmanArr = Approval.data.data;
        this.spmanArr.map((v, i) => {
          if (v.userId == localStorage.getItem("id")) {
            this.currentSpman = v;
            this.currentSpman.index = i + 1;
          }
        });
      }
    },
    // 获取表格数据
    async getList() {
      this.tableLoad = true;
      let params = {
        // page: this.page,
        // size: this.size,
        ...this.screenForm,
      };
      let res = await remindReturnedMoney(params);
      this.pageTotal = res.data.total;
      this.tableData = res.data.data;
      this.tableLoad = false;
      // statusCode  合计金额
      console.log("返回参数", res.data);
      Message.success("已刷新数据");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-steps {
  .el-step__main {
    .el-step__title {
      color: #333;
      font-weight: bold;
    }
    .el-step__description {
      // color: #000;
    }
  }
}

/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278FE;
  border-color: #3278FE;
  &:hover {
    opacity: 0.7;
  }
}

#niche {
  .top-box {
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
    // line-height: 30px;
    margin-bottom: 40px;
    /deep/ .el-select {
      width: 100%;
    }
    .select-item {
      width: 20%;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        min-width: 60px;
        text-align: right;
        align-items: center;
        font-size: 14px;
      }
    }
  }
  .search {
    display: flex;
    .left {
      width: 50%;
      display: flex;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;
      line-height: 30px;
      .left-wrap {
        display: flex;
        align-items: center;
      }
    }
    .right {
      width: 50%;
      text-align: right;
      display: flex;
      /* justify-content: space-between; */
      justify-content: flex-end;
      align-items: center;
      height: 100%;
    }
  }
  /deep/ .el-table {
    // max-height: 555px;
    // padding-left: 50px;
    // box-sizing: border-box;
    // overflow-y: scroll;
  }
  /deep/.el-dialog__wrapper {
    .el-dialog {
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      .el-step {
        .el-step__head {
          display: flex;
        }
      }
      .el-select,
      .el-input {
        width: 100%;
      }
      .el-textarea__inner {
        min-height: 100px !important;
      }
      .el-dialog__header {
        text-align: center;
        .el-dialog__title {
          font-weight: bold;
          font-size: 20px;
        }
      }
      .el-dialog__body {
        position: relative;
        // min-height: 500px;
        .el-form {
        }
        .el-form-item__content {
          // 修改样式会混乱
          .btn-box {
            text-align: right;
          }
        }
      }
    }
  }
}
main {
  .table-box {
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 480px;
    overflow-y: scroll;
    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style: none;
    /*火狐下隐藏滚动条*/
    scrollbar-width: none;
  }
  .table-box::-webkit-scrollbar {
    display: none;
  }
  .pagination {
    text-align: right;
    margin: 20px 0;
  }
}
</style>